import { Button, ButtonProps } from 'components/atoms/Button'
import { SignUpForUpdatesModal } from 'components/organisms/Modals'
import { useState } from 'react'
import { PotentialOffering } from 'types'
import { usePotentialInvestStatus } from 'hooks/calls/usePotentialInvestStatus'

interface SignUpForUpdatesButtonProps extends Pick<ButtonProps, 'view' | 'className'> {
  portfolio: PotentialOffering
  onSignUpClose?: () => void
}

export const SignUpForUpdatesButton = ({ portfolio, onSignUpClose, className, view }: SignUpForUpdatesButtonProps) => {
  const [isModalOpened, setModalOpened] = useState(false)
  const { isLoading, investStatus } = usePotentialInvestStatus(portfolio)

  const showNotice = !isLoading && investStatus !== 'allowed'

  const handleClose = () => {
    setModalOpened(false)
    onSignUpClose?.()
  }

  return (
    <SignUpForUpdatesModal
      portfolioId={portfolio.id}
      showNotice={showNotice}
      trigger={
        <Button className={className} view={view} tabIndex={-1}>
          Sign up
        </Button>
      }
      opened={isModalOpened}
      setOpened={setModalOpened}
      onClose={handleClose}
    />
  )
}
