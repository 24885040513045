import { ReactNode } from 'react'
import styled from 'styled-components'
import { BaseBadge, BaseBadgeProps } from '../Badge'

type FilledBadgeColor = 'orange' | 'gray' | 'green' | 'darkGreen' | 'darkGray'

export interface FilledBadgeProps extends BaseBadgeProps {
  color: FilledBadgeColor
  children?: ReactNode
}

export const FilledBadge = ({ children, ...props }: FilledBadgeProps) => (
  <FilledBadgeContainer {...props}>{children}</FilledBadgeContainer>
)

const FilledBadgeContainer = styled(BaseBadge)<FilledBadgeProps>`
  color: ${({ theme }) => theme.colors.White};
  background-color: ${({ theme, color }) => {
    switch (color) {
      case 'gray':
      default:
        return theme.colors.Edward
      case 'green':
        return theme.colors.Emerald
      case 'darkGreen':
        return theme.colors.OxidizedGreen
      case 'orange':
        return theme.colors.CopperOrange
      case 'darkGray':
        return theme.colors.Text02
    }
  }};
`
