import { useAIPBackendMutation } from 'hooks/httpCalls/useAIPBackendMutation'
import { SignUpData } from 'types/SignUpForUpdates'
import { useCallback } from 'react'
import { SignUpForUpdatesPostData } from 'backend/utils/validation/signUpForPortfolio'

export function useSignUpForPotentialOfferingUpdates(portfolioId: string) {
  const { mutateAsync, isError, isSuccess, reset } = useAIPBackendMutation<SignUpForUpdatesPostData>(
    `/api/potential-offering/sign-up/${portfolioId}`,
    'POST',
  )
  const signUp = useCallback((data: SignUpData) => mutateAsync(data), [mutateAsync])
  return { signUp, isError, isSuccess, reset }
}
