import { isPotentialOffering, PotentialOffering } from 'types'
import { Offering } from 'types/Portfolio/StructuredOffering'

export function getOfferingPreviewUrl(portfolio: Offering | PotentialOffering): string
export function getOfferingPreviewUrl(id: string): string

export function getOfferingPreviewUrl(portfolioOrId: Offering | PotentialOffering | string): string {
  if (typeof portfolioOrId === 'string') return getUrl(portfolioOrId)

  return isPotentialOffering(portfolioOrId) ? getUrl(portfolioOrId.id) : getUrl(portfolioOrId.address)
}

const getUrl = (id: string) => `/offering/${id}`
