import { formatPortfolioTerm } from 'utils/formatting'
import { isPotentialOffering, PotentialOffering } from 'types/Portfolio'
import { isEvergreenPortfolio, isInCapitalFormationPeriod } from 'domain/offering/index'
import { Offering } from 'types/Portfolio/StructuredOffering'
import { addSeconds } from 'date-fns'

export function getPortfolioTerm(portfolio: Offering | PotentialOffering) {
  let { startDate, endDate } = portfolio

  if (!isPotentialOffering(portfolio) && isInCapitalFormationPeriod(portfolio)) {
    startDate = new Date(Date.now())
    endDate = addSeconds(startDate, portfolio.duration)
  }

  if (isEvergreenPortfolio(endDate)) {
    return 'Evergreen'
  }

  return formatPortfolioTerm(startDate, endDate)
}
