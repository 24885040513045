import { Contract } from 'ethers'
import { Call, Falsy, useCall, useEthers } from '@usedapp/core'

import { PotentialOffering } from 'types/Portfolio'
import { getPortfolioRestriction, InvestorRestriction } from 'domain/investor/restrictions'
import { getContractAddress } from 'utils'
import { SUPPORTED } from 'domain/investor/restrictions/definitions'
import { SupportedChainId } from 'constants/chain'

export type PotentialInvestStatus = 'allowed' | 'lenderRestricted' | 'accountNotConnected'

export function usePotentialInvestStatus(offering: PotentialOffering): {
  investStatus: PotentialInvestStatus
  isLoading: boolean
} {
  const { account } = useEthers()
  const lenderVerifierAddress = getLenderVerifierAddress(offering.investRequirements, offering.chainId)
  const { restriction, check } = getPortfolioRestriction(lenderVerifierAddress, offering.chainId, account)

  const call: Falsy | Call = account &&
    check?.callData && {
      args: check.callData.args,
      contract: new Contract(check.callData.address, check.callData.abi),
      method: check.callData.functionName,
    }
  const isAllowedByContract: boolean | undefined = useCall(call, { chainId: offering.chainId })?.value
  const isLoading = !!call && isAllowedByContract === undefined

  function getStatus() {
    switch (true) {
      case !account:
        return 'accountNotConnected'
      case restriction === 'AllowAll' || isAllowedByContract:
        return 'allowed'
      default:
        return 'lenderRestricted'
    }
  }

  return { isLoading, investStatus: getStatus() }
}

const getLenderVerifierAddress = (restriction: InvestorRestriction, chainId: SupportedChainId): string | undefined => {
  const definition = SUPPORTED.find(({ restriction: _restriction }) => _restriction === restriction)

  if (definition) {
    return getContractAddress(definition.contract, chainId)
  }
}
