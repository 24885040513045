import styled from 'styled-components'
import { Row } from 'components/atoms/Row'
import { Text } from 'components/atoms/Text'
import { CalendarIcon } from 'components/atoms/Icons'
import { formatPortfolioFundraisingPeriod } from 'utils/formatting'

interface TimeLeftProps {
  endDate: Date
}

export const TimeLeft = ({ endDate }: TimeLeftProps) => {
  return (
    <Wrapper>
      <IconWrapper>
        <CalendarIcon />
      </IconWrapper>
      <Text variant="body3" color="light">
        {formatPortfolioFundraisingPeriod(endDate)}
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled(Row)`
  gap: 4px;
`

export const IconWrapper = styled.div`
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.Corduroy};
`
