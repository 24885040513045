import { FilledBadge, FilledBadgeProps } from 'components/atoms/FilledBadge'
import { PortfolioStatus } from 'types/Portfolio'

interface PortfolioStatusBadgeProps {
  status: PortfolioStatus
}

export const PortfolioStatusBadge = ({ status }: PortfolioStatusBadgeProps) => {
  return (
    <FilledBadge color={getBadgeColor(status)} textSize="small" bold>
      {status}
    </FilledBadge>
  )
}

function getBadgeColor(status: PortfolioStatus): FilledBadgeProps['color'] {
  switch (status) {
    case PortfolioStatus.Closed:
      return 'gray'
    case PortfolioStatus.Full:
      return 'darkGray'
    case PortfolioStatus.Fundraising:
      return 'green'
    case PortfolioStatus.Open:
      return 'orange'
    case PortfolioStatus.Preview:
      return 'darkGreen'
  }
}
