import { estimatedInvestmentSchema } from 'domain/investor/investments/estimatedInvestment'
import { emailSchema, requiredError } from 'utils/validation'
import * as yup from 'yup'

export const signUpFormSchema = yup.object({
  name: yup
    .string()
    .required(requiredError)
    .min(2, 'Name should be min 2 characters long')
    .matches(/^[\p{L} '`-]*$/gmu, 'Name can only contain letters'),
  email: emailSchema.required(requiredError),
  estimatedInvestment: estimatedInvestmentSchema.required(),
  message: yup.string(),
})

export type SignUpFormData = yup.InferType<typeof signUpFormSchema>
