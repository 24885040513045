import { Text } from 'components/atoms/Text'
import { StatusModal } from 'components/organisms/Modals'
import styled from 'styled-components'
import { LinkButton } from 'components/atoms/LinkButton'
import { ArrowForwardIcon } from 'components/atoms/Icons'
import { Button } from 'components/atoms/Button'
import { isFeatureEnabled } from 'utils'
import { useEthers } from '@usedapp/core'
import { useUser } from '@supabase/auth-helpers-react'

export interface SignUpForUpdatesSuccessModalProps {
  showNotice: boolean
  onClose: () => void
}

export const SignUpForUpdatesSuccessModal = ({ showNotice, onClose }: SignUpForUpdatesSuccessModalProps) => {
  return (
    <StatusModal
      state="Success"
      message="Thank you for the signing up. You will be informed about launch updates and future opportunities."
      onClose={onClose}
      centerMessage
    >
      {({ closeModal }) => <ModalContent showNotice={showNotice} closeModal={closeModal} />}
    </StatusModal>
  )
}

interface ModalContentProps {
  closeModal: () => void
  showNotice: boolean
}

const ModalContent = ({ showNotice, closeModal }: ModalContentProps) => {
  const { account } = useEthers()
  const user = useUser()
  const loggedIn = !!account || !!user
  if (!showNotice || !isFeatureEnabled('kycFlow')) {
    if (loggedIn) {
      return (
        <LinkButton view="tertiary" icon={<ArrowForwardIcon />} iconPosition="right" href="/my-investments">
          My Investments
        </LinkButton>
      )
    }

    return (
      <LinkButton view="tertiary" icon={<ArrowForwardIcon />} iconPosition="right" href="/">
        <span onClick={closeModal}>Other Offerings</span>
      </LinkButton>
    )
  }

  return (
    <>
      <VerificationNote bold>Final investments require an identity verification process.</VerificationNote>
      <ButtonsGroup>
        <LinkButton href="/verify" icon={<ArrowForwardIcon />} iconPosition="right" view="tertiary">
          Verify Identity
        </LinkButton>
        <SkipButton view="link" narrow onClick={closeModal}>
          Skip for now
        </SkipButton>
      </ButtonsGroup>
    </>
  )
}

const VerificationNote = styled(Text)`
  color: ${({ theme }) => theme.colors.White};
`

const SkipButton = styled(Button)`
  color: ${({ theme }) => theme.colors.OxidizedGreen};
`

const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
`
