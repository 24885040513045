import { isPotentialOffering, LiveDataOffering, PotentialOffering } from 'types'
import { Statistic } from 'components/molecules/Statistic'
import { StatisticLabel } from 'components/Portfolios/PortfolioCard/PortfolioStatistics'
import styled from 'styled-components'
import { getOfferingApy } from 'domain/offering/getOfferingApy'

interface Props {
  portfolio: LiveDataOffering | PotentialOffering
}

export const PortfolioCardApy = ({ portfolio }: Props) => {
  const showEstimatedApy = !isPotentialOffering(portfolio)

  if (showEstimatedApy || portfolio.targetApy) {
    return (
      <Statistic
        view="column"
        label={
          <StatisticLabel color="light" variant="body2">
            Annualized Target Return
          </StatisticLabel>
        }
        text={getOfferingApy(portfolio)}
        extraBold
      />
    )
  }

  return <EmptyPlaceholder />
}

const EmptyPlaceholder = styled.div`
  height: 42px;
`
