import { Button } from 'components/atoms/Button'
import { StatusModal } from 'components/organisms/Modals'

export interface SignUpErrorModalProps {
  onRetry: () => void
  onClose: () => void
}

export const SignUpForUpdatesErrorModal = ({ onRetry, onClose }: SignUpErrorModalProps) => {
  return (
    <StatusModal state="Fail" message="Action went wrong. Please try again" centerMessage onClose={onClose}>
      {({ closeModal }) => (
        <Button
          view="tertiary"
          onClick={() => {
            closeModal()
            onRetry()
          }}
        >
          Try Again
        </Button>
      )}
    </StatusModal>
  )
}
