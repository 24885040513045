import { CloseButton, Modal, ModalBody, ModalProps } from 'components/organisms/Modals/index'
import styled, { css } from 'styled-components'
import bg from '/public/images/interestedInOfferingVertical.jpg'
import { Logo } from 'components/atoms/Logo'
import Image from 'next/image'
import {
  SignUpFormData,
  signUpFormSchema,
  SignUpForUpdatesForm,
} from 'components/organisms/Modals/SignUpForUpdatesModal/SignUpForUpdatesForm'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSignUpForPotentialOfferingUpdates } from 'hooks/httpCalls'
import { SignUpForUpdatesSuccessModal } from './SignUpForUpdatesSuccessModal'
import { SignUpForUpdatesErrorModal } from './SignUpForUpdatesErrorModal'
import { SignUpData } from 'types/SignUpForUpdates'
import { useMemo } from 'react'
import { trackConversion } from 'utils/analytics'
import { useWidthBreakpoint } from 'hooks'
import { estimatedInvestment } from 'domain/investor/investments/estimatedInvestment'

export type SignUpForUpdatesModal = {
  portfolioId: string
  showNotice: boolean
  opened: boolean
  setOpened: (opened: boolean) => void
} & Pick<ModalProps, 'trigger' | 'onClose'>

export const SignUpForUpdatesModal = ({
  portfolioId,
  showNotice,
  trigger,
  setOpened,
  onClose,
  opened,
}: SignUpForUpdatesModal) => {
  const { signUp, isSuccess, isError, reset: resetSignUpState } = useSignUpForPotentialOfferingUpdates(portfolioId)
  const formContext = useForm<SignUpFormData>({
    resolver: yupResolver(signUpFormSchema),
    mode: 'onChange',
    defaultValues: {
      estimatedInvestment: estimatedInvestment.min,
    },
  })

  const modalToDisplay: 'form' | 'success' | 'error' = useMemo(() => {
    switch (true) {
      case isSuccess:
        return 'success'
      case isError:
        return 'error'
      default:
        return 'form'
    }
  }, [isSuccess, isError])

  const handleClose = () => {
    onClose?.()
    resetSignUpState()
    formContext.reset()
  }
  const handleSignUp = formContext.handleSubmit(async (data) => {
    try {
      await signUp(data as SignUpData)
      trackConversion('SignupPreviewPortfolio', { offering: portfolioId })
    } catch (e) {}
  })

  const isMobileView = useWidthBreakpoint('tabletAndSmaller')

  return (
    <FormProvider {...formContext}>
      {modalToDisplay === 'form' && (
        <StyledModal
          width={!isMobileView ? 1015 : 330}
          content={
            <Container>
              {!isMobileView && (
                <BannerSection>
                  <StyledImage alt="" src={bg} fill quality={50} />
                  <Logo colored color="light" />
                  <BannerContent>
                    <BannerTitle>
                      <em>Interested</em> in this opportunity?
                    </BannerTitle>
                    <BannerDescription>
                      Sign up for updates about this opportunity and others on Archblock.
                    </BannerDescription>
                    <BannerDescription>
                      Help guarantee your allocation when this opportunity is launched by staying informed about launch
                      updates and future opportunities.
                    </BannerDescription>
                  </BannerContent>
                </BannerSection>
              )}
              <StyledSignUpForUpdatesForm showNotice={showNotice} onSubmit={handleSignUp} />
            </Container>
          }
          hideHeader
          trigger={trigger}
          opened={opened}
          toggleOpened={setOpened}
          onClose={handleClose}
        />
      )}
      {modalToDisplay === 'success' && <SignUpForUpdatesSuccessModal showNotice={showNotice} onClose={handleClose} />}
      {modalToDisplay === 'error' && <SignUpForUpdatesErrorModal onRetry={handleSignUp} onClose={handleClose} />}
    </FormProvider>
  )
}

const StyledModal = styled(Modal)`
  ${ModalBody} {
    padding: 0;
  }

  ${CloseButton} {
    @media ${({ theme }) => theme.queries.tabletAndSmaller} {
      top: 16px;
    }
  }
`

const containerStyles = css`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 455px 1fr;
  position: relative;
  overflow-y: scroll;
  max-height: 90vh;

  @media ${({ theme }) => theme.queries.tabletAndSmaller} {
    ${containerStyles};
    height: 90vh;
  }

  @media ${({ theme }) => theme.queries.tabletAndSmaller} and (orientation: portrait) {
    ${containerStyles};
    height: 100%;
  }

  @media ${({ theme }) => theme.queries.mobileAndSmaller} {
    height: 90vh;
  }
`

const BannerSection = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;

  > div {
    z-index: 1;
  }
`

const BannerContent = styled.div`
  position: relative;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: rgba(42, 42, 42, 0.6);
`

const BannerTitle = styled.h2`
  color: ${({ theme }) => theme.colors.White};
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;

  em {
    font-style: normal;
    color: ${({ theme }) => theme.colors.DarkMessageBackgrounds};
    display: block;
  }
`

const BannerDescription = styled.p`
  color: ${({ theme }) => theme.colors.Iron};
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`

const StyledImage = styled(Image)`
  mix-blend-mode: luminosity;
`

const StyledSignUpForUpdatesForm = styled(SignUpForUpdatesForm)`
  margin: 23.5px 40px 23.5px 40px;
  height: 758px;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.queries.tabletAndSmaller} {
    margin: 0;
    height: auto;
  }
`
