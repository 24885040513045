import styled from 'styled-components'
import { Text } from 'components/atoms/Text'
import { isPotentialOffering, LiveDataOffering, PotentialOffering } from 'types'
import { getMinimumInvestment, getPortfolioTerm } from 'domain/offering'
import { CurrencyStatistic, Statistic } from 'components/molecules/Statistic'
import { CurrencyValue } from '@usedapp/core'
import { hasCustomization } from 'config/customizations'

interface Props {
  portfolio: LiveDataOffering | PotentialOffering
}

export const PortfolioStatistics = ({ portfolio }: Props) => {
  const minimumInvestment = getMinimumInvestment(portfolio)
  const isPotential = isPotentialOffering(portfolio)
  const fundraiseOrTotal = isPotential
    ? new CurrencyValue(portfolio.asset, portfolio.targetFundraise)
    : portfolio.totalAssets
  const hideValue = hasCustomization(portfolio, 'HidePortfolioValue')

  return (
    <StatisticsWrapper>
      {!hideValue && (
        <CurrencyStatistic
          view="row"
          label={
            <StatisticLabel color="light" variant="body2">
              {isPotential ? 'Target Fundraise' : 'Value'}
            </StatisticLabel>
          }
          value={fundraiseOrTotal}
          bold
          wide
        />
      )}
      {hideValue && <EmptyPlaceholder />}
      <CurrencyStatistic
        view="row"
        label={
          <StatisticLabel color="light" variant="body2">
            Minimum Commitment
          </StatisticLabel>
        }
        value={minimumInvestment && new CurrencyValue(portfolio.asset, minimumInvestment)}
        bold
        wide
      />
      <Statistic
        view="row"
        label={
          <StatisticLabel color="light" variant="body2">
            Portfolio Term
          </StatisticLabel>
        }
        text={getPortfolioTerm(portfolio)}
        bold
        wide
      />
    </StatisticsWrapper>
  )
}

const StatisticsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const StatisticLabel = styled(Text)`
  line-height: 1;
`

const EmptyPlaceholder = styled.div`
  height: 24px;
`
