import { useRouter } from 'next/router'
import { MouseEvent } from 'react'

export function useOpenLink(path: string) {
  const router = useRouter()
  const openInNewTab = () => window.open(window.location.origin + path, '_blank')

  return {
    onClick: (event: MouseEvent) => {
      if (event.ctrlKey || event.metaKey) {
        return openInNewTab()
      }
      router.push(path)
    },
    onAuxClick: openInNewTab,
  }
}
