import * as yup from 'yup'

export const estimatedInvestment = {
  min: 1,
  sliderMax: 1000000,
  max: Number.MAX_SAFE_INTEGER,
  step: 10000,
}

export const estimatedInvestmentSchema = yup
  .number()
  .typeError('Required input')
  .min(estimatedInvestment.min, `Estimated investment must be greater than or equal to ${estimatedInvestment.min}`)
  .max(estimatedInvestment.max)
