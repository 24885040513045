import { isPotentialOffering, PotentialOffering } from 'types'
import { shortAddress } from 'utils'
import { Offering } from 'types/Portfolio/StructuredOffering'

export function getManagerName(portfolio: Offering | PotentialOffering) {
  if (isPotentialOffering(portfolio)) {
    return portfolio.manager.name
  }
  if (portfolio.manager.name) {
    return portfolio.manager.name
  }
  return shortAddress(portfolio.manager.address)
}
