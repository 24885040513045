import styled, { useTheme } from 'styled-components'
import { useFormContext } from 'react-hook-form'
import { Text } from 'components/atoms/Text'
import { FormInput } from 'components/molecules/FormInput'
import { FormWrapper } from 'components/atoms/Form'
import { Separator } from 'components/atoms/Separator'
import { FormTextArea } from 'components/molecules/FormTextArea'
import { Button } from 'components/atoms/Button'
import { SignUpFormData } from './schema'
import { Notification } from 'components/molecules/Notification'
import { InfoIcon } from 'components/atoms/Icons'
import { isFeatureEnabled } from 'utils'
import { SliderInput, StyledInputWrapper } from 'components/molecules/SliderInput'
import { StyledInput } from 'components/atoms/PercentageInput'
import { Asterisk, AsteriskWrapper } from 'components/atoms/Asterisk'
import { estimatedInvestment } from 'domain/investor/investments/estimatedInvestment'

const MESSAGE_PLACEHOLDER = `Please provide us more details such:

  • expected return on investment
  • duration of the investment
  • investment experience
`

const getId = (id: keyof SignUpFormData) => id

export interface FormProps {
  showNotice: boolean
  onSubmit: () => void
  className?: string
}

const { min, sliderMax, max, step } = estimatedInvestment
export const SignUpForUpdatesForm = ({ showNotice, onSubmit, className }: FormProps) => {
  const {
    formState: { isSubmitting },
  } = useFormContext<SignUpFormData>()

  const theme = useTheme()

  return (
    <div className={className}>
      <Title variant="title3" bold color="dark">
        Sign up for updates
      </Title>
      <FormWrapperStyled>
        {showNotice && isFeatureEnabled('kycFlow') && (
          <StyledNotification mode="gray" icon={<InfoIcon color={theme.colors.Text02} />}>
            Final investments require an identity verification process.
          </StyledNotification>
        )}
        <FormInput id={getId('name')} label="Name" placeholder="John Doe" isRequired />
        <FormInput id={getId('email')} label="Email" placeholder="example@email.com" isRequired />
        <Separator />
        <SliderInputWrapper>
          <Text bold color="dark">
            What is your estimated investment amount? <Asterisk />
          </Text>
          <SliderInput
            id={getId('estimatedInvestment')}
            min={min}
            max={max}
            sliderMax={sliderMax}
            step={step}
            innerSuffix="$"
            sliderValue="1,000,000 +"
            formatValue
            onSliderValueChange={roundDown}
          />
        </SliderInputWrapper>
        <Separator />
        <Text bold color="dark">
          Would you like to leave us a message? (Optional)
        </Text>
        <StyledFormTextArea id={getId('message')} label="Message" placeholder={MESSAGE_PLACEHOLDER} />
        <SubmitButton view="primary" onClick={onSubmit} loading={isSubmitting}>
          Confirm
        </SubmitButton>
      </FormWrapperStyled>
    </div>
  )
}

const roundDown = ([value]: number[]): number[] => {
  if (value < step) {
    return [value]
  }

  const newValue = value / 10

  return [Math.floor(newValue) * 10]
}

const Title = styled(Text)`
  margin-bottom: 24px;

  @media ${({ theme }) => theme.queries.tabletAndSmaller} {
    padding: 20px 0 24px 24px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.Iron};
  }
`

const FormWrapperStyled = styled(FormWrapper).attrs({ as: 'form' })`
  gap: 24px;
  height: 100%;

  @media ${({ theme }) => theme.queries.tabletAndSmaller} {
    max-width: 90%;
    margin: auto;
    padding-bottom: 15px;
  }
`

const SubmitButton = styled(Button)`
  margin-left: auto;
  margin-top: auto;

  @media ${({ theme }) => theme.queries.tabletAndSmaller} {
    width: 100%;
    min-height: 40px;
  }
`

const StyledNotification = styled(Notification)`
  align-items: center;
`

const StyledFormTextArea = styled(FormTextArea)`
  textarea {
    resize: vertical;
    max-height: 250px;

    @media ${({ theme }) => theme.queries.tabletAndSmaller} {
      height: 166px;
    }
  }
`

const SliderInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  ${AsteriskWrapper} {
    font-size: 14px;
  }

  ${StyledInputWrapper} {
    max-width: 253px;

    @media ${({ theme }) => theme.queries.tabletAndSmaller} {
      display: none;
    }
  }

  ${StyledInput} {
    max-width: 211px;

    @media ${({ theme }) => theme.queries.tabletAndSmaller} {
      max-width: unset;
    }
  }
`
