import { LiveDataOffering, PortfolioStatus, PotentialOffering } from 'types'
import { PortfolioStatusBadge } from 'components/Portfolios/PortfolioDetails/PortfolioStatusBadge'
import { IconWrapper, TimeLeft } from 'components/molecules/TimeLeft'
import { isEvergreenPortfolio, isInCapitalFormationPeriod } from 'domain/offering'
import { EditDocumentIcon } from 'components/atoms/Icons'
import { Text } from 'components/atoms/Text'
import styled from 'styled-components'
import { BaseBadge } from 'components/atoms/Badge'
import { Row } from 'components/atoms/Row'
import { useWidthBreakpoint } from 'hooks'

type Variant = 'grid' | 'preview'

interface StatusNoteProps {
  portfolio: LiveDataOffering | PotentialOffering
  variant: Variant
  className?: string
}

export const PortfolioStatusNote = ({ portfolio, className, variant }: StatusNoteProps) => {
  const isMobileLayout = useWidthBreakpoint('largeMobileAndSmaller')
  const shouldWrap = isMobileLayout && variant === 'preview'

  if (shouldWrap) {
    return <Content portfolio={portfolio} />
  }

  return (
    <PortfolioStatusWrapper variant={variant} className={className}>
      <Content portfolio={portfolio} />
    </PortfolioStatusWrapper>
  )
}

function Content({ portfolio }: { portfolio: LiveDataOffering | PotentialOffering }) {
  const { endDate, status } = portfolio
  const isEvergreen = isEvergreenPortfolio(endDate)
  const shouldDisplayTimeLeft = status !== PortfolioStatus.Closed && status !== PortfolioStatus.Preview && !isEvergreen

  return (
    <>
      <PortfolioStatusBadge status={status} />
      <SideTextWrapper>
        {shouldDisplayTimeLeft && (
          <TimeLeft endDate={isInCapitalFormationPeriod(portfolio) ? portfolio.startDeadline : endDate} />
        )}
        {status === PortfolioStatus.Preview && <PreviewPortfolioSideText />}
      </SideTextWrapper>
    </>
  )
}

const PreviewPortfolioSideText = () => {
  return (
    <Wrapper>
      <IconWrapper>
        <EditDocumentIcon />
      </IconWrapper>
      <Text variant="body3" color="light">
        Sign up for updates
      </Text>
    </Wrapper>
  )
}

const PortfolioStatusWrapper = styled(BaseBadge)<{ variant: Variant }>`
  padding: 0;
  border: none;
  background-color: ${({ theme }) => theme.colors.White};
  gap: ${({ variant }) => (variant === 'grid' ? '4px' : '10px')};
`

const SideTextWrapper = styled.span`
  color: ${({ theme }) => theme.colors.Text01};
  margin-right: 10px;
`

const Wrapper = styled(Row)`
  gap: 4px;
`
