import { defaultImage } from 'constants/defaultImage'
import { PotentialOffering } from 'types'
import { Offering } from 'types/Portfolio/StructuredOffering'

export function getManagerImage(portfolio: Offering | PotentialOffering) {
  if (portfolio.manager.imageUrl) {
    return portfolio.manager.imageUrl
  }
  return defaultImage
}
