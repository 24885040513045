import { track } from '@vercel/analytics'

type ConversionType = 'SignupPreviewPortfolio'

function postGtagEvent(eventType: string, params: Record<string, unknown>) {
  window.gtag('event', eventType, params)
}

export function trackConversion(type: ConversionType, data: Record<string, string | number>) {
  track('SignupPreviewPortfolio', data)
  postGtagEvent('generate_lead', {
    ...data,
    type,
  })
}
