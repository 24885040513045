import { Img } from 'components/atoms/Image'
import { Separator } from 'components/atoms/Separator'
import { BorderRadiuses, Overflows, shadows } from 'styles'
import { Row } from 'components/atoms/Row'
import { Column } from 'components/atoms/Column'
import { Text, TextInline } from 'components/atoms/Text'
import { useState } from 'react'
import styled from 'styled-components'
import { LiveDataOffering, PotentialOffering } from 'types/Portfolio'
import { LinkButton } from 'components/atoms/LinkButton'
import { stripMarkdown } from 'utils/formatting'
import { useOpenLink } from 'hooks'
import { PortfolioStatusNote } from 'components/Portfolios/PortfolioStatusNote'
import { stopClickPropagation } from 'utils/stopClickPropagation'
import { PortfolioStatistics, StatisticLabel } from './PortfolioStatistics'
import { getManagerName, getManagerProfileUrl, getPortfolioId, getOfferingPreviewUrl } from 'domain/offering'
import { CardButtonsContainer, PortfolioCardButtons } from './PortfolioCardButtons'
import { getManagerImage } from 'domain/offering/getManagerImage'
import { PortfolioCardApy } from 'components/Portfolios/PortfolioCard/PortfolioCardApy'

interface PortfolioCardProps {
  portfolio: LiveDataOffering | PotentialOffering
}

export function PortfolioCard({ portfolio }: PortfolioCardProps) {
  const [isHovering, setIsHovering] = useState(false)
  const { name, descriptionSections } = portfolio
  const overviewPath = getOfferingPreviewUrl(portfolio)
  const onClickActions = useOpenLink(overviewPath)
  const shortDescription = !!descriptionSections.length && stripMarkdown(descriptionSections[0].content)
  const id = getPortfolioId(portfolio)

  return (
    <NotePlacementWrapper>
      <PortfolioStatusNoteStyled variant="grid" portfolio={portfolio} />
      <PortfolioCardContainer
        {...onClickActions}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        isHovering={isHovering}
        tabIndex={0}
      >
        <PortfolioCardTopSection>
          <PortfolioInfo>
            <PortfolioTitle variant="title2" extraBold color="dark">
              {name}
            </PortfolioTitle>
            <ManagerInfo onClick={stopClickPropagation}>
              <Img image={getManagerImage(portfolio)} size={20} />
              <LinkButton href={getManagerProfileUrl(portfolio)}>
                <ManagerName>{getManagerName(portfolio)}</ManagerName>
              </LinkButton>
            </ManagerInfo>
          </PortfolioInfo>
          <Description color="light" variant="body2">
            {shortDescription}
          </Description>
        </PortfolioCardTopSection>
        <PortfolioCardBottomSection id={`${id}-bottom-section`}>
          <PortfolioCardApy portfolio={portfolio} />
          <Separator />
          <PortfolioStatistics portfolio={portfolio} />
          <PortfolioCardButtons
            portfolio={portfolio}
            onClickActions={onClickActions}
            onSignUpClose={() => setIsHovering(false)}
          />
        </PortfolioCardBottomSection>
      </PortfolioCardContainer>
    </NotePlacementWrapper>
  )
}

const CARD_MIN_WIDTH = 330
const CARD_PADDING = 24

export const PortfolioCardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${CARD_MIN_WIDTH}px, 1fr));
  grid-gap: 24px;

  @media ${({ theme }) => theme.queries.desktopAndSmaller} {
    grid-gap: 20px;
    grid-template-columns: repeat(3, minmax(${CARD_MIN_WIDTH}px, 1fr));
  }

  @media ${({ theme }) => theme.queries.laptopAndSmaller} {
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(${CARD_MIN_WIDTH}px, 1fr));
  }
`

const PortfolioStatusNoteStyled = styled(PortfolioStatusNote)`
  position: absolute;
  z-index: 2;
  top: 1px;
  margin-left: ${CARD_PADDING}px;
`

const PortfolioCardSection = styled(Column)`
  row-gap: 16px;
  justify-content: space-between;
`

const PortfolioCardTopSection = styled(PortfolioCardSection)`
  flex: 1;
`

const PortfolioCardBottomSection = styled(PortfolioCardSection)`
  background-color: ${({ theme }) => theme.colors.White};
  transition: transform 0.2s ease-out;

  ${StatisticLabel} {
    line-height: 18px;
  }

  ${TextInline} {
    line-height: 20px;
  }
`

export const PortfolioCardContainer = styled(Column)<{ isHovering: boolean; tabIndex: number }>`
  justify-content: space-between;
  row-gap: 16px;
  height: 330px;
  width: 100%;
  min-width: ${CARD_MIN_WIDTH}px;
  padding: ${CARD_PADDING}px;
  background: ${({ theme }) => theme.colors.White};
  border: 1px solid ${({ theme }) => theme.colors.Mouse};
  border-radius: ${BorderRadiuses.m};
  overflow: hidden;
  cursor: pointer;
  margin-top: 16px;

  &:hover,
  &:focus {
    box-shadow: ${shadows.s};

    ${PortfolioCardBottomSection} {
      transform: translateY(-58px);
    }

    & ${CardButtonsContainer} {
      opacity: 1;
    }
  }

  & ${PortfolioCardBottomSection} {
    transform: translateY(${({ isHovering }) => (isHovering ? -58 : 0)}px);
  }

  & ${CardButtonsContainer} {
    opacity: ${({ isHovering }) => (isHovering ? 1 : 0)};
  }

  &:focus-visible {
    outline: none;
  }
`

const NotePlacementWrapper = styled.div`
  position: relative;
`

const PortfolioInfo = styled(Column)`
  row-gap: 8px;
`

export const PortfolioTitle = styled(Text)`
  ${Overflows.FullDots}
`

const Description = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 42px;
  min-height: 42px;
`

export const ManagerInfo = styled(Row)`
  width: fit-content;
  gap: 8px;
  color: ${({ theme }) => theme.colors.Elm};
`

export const ManagerName = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;

  ${Overflows.FullDots}
  &:hover {
    color: ${({ theme }) => theme.colors.OxidizedGreen};
  }
`
