import { SUPPORTED_CHAINS } from 'constants/chain'
import { isPotentialOffering, PotentialOffering } from 'types'
import { Offering } from 'types/Portfolio/StructuredOffering'

export function getManagerProfileUrl(portfolio: Offering | PotentialOffering) {
  if (isPotentialOffering(portfolio) || portfolio.manager.userId) return `/profile/${portfolio.manager.userId}`
  return getManagerEtherscanUrl(portfolio)
}

export function getManagerEtherscanUrl(portfolio: Pick<Offering, 'chainId' | 'manager'>) {
  const portfolioChain = SUPPORTED_CHAINS.find((chain) => chain.chainId === portfolio.chainId)
  return portfolioChain?.getExplorerAddressLink(portfolio.manager.address) ?? '/'
}
