import { Button } from 'components/atoms/Button'
import { LoaderIcon } from 'components/atoms/Icons'
import { Row } from 'components/atoms/Row'
import { useOpenLink } from 'hooks'
import { useInvestStatus } from 'hooks/calls'
import styled, { css } from 'styled-components'
import { isPotentialOffering, LiveDataOffering, PortfolioStatus, PotentialOffering } from 'types'
import { stopClickPropagation } from 'utils/stopClickPropagation'
import { InvestButton } from '../OfferingButtons/InvestButton'
import { SignUpForUpdatesButton } from 'components/Portfolios/OfferingButtons/SignUpForUpdatesButton'
import { useUser } from '@supabase/auth-helpers-react'

interface CardButtonsProps<T> {
  portfolio: T
  onClickActions: ReturnType<typeof useOpenLink>
  onSignUpClose: () => void
}

export const PortfolioCardButtons = ({
  portfolio,
  onClickActions,
  onSignUpClose,
}: CardButtonsProps<LiveDataOffering | PotentialOffering>) => {
  return isPotentialOffering(portfolio) ? (
    <PotentialOfferingCardButtons portfolio={portfolio} onClickActions={onClickActions} onSignUpClose={onSignUpClose} />
  ) : (
    <RealPortfolioCardButtons portfolio={portfolio} onClickActions={onClickActions} />
  )
}

const PotentialOfferingCardButtons = ({
  portfolio,
  onClickActions,
  onSignUpClose,
}: CardButtonsProps<PotentialOffering>) => {
  return (
    <CardButtonsContainer onClick={stopClickPropagation}>
      <ButtonStyled view="secondary" wide {...onClickActions} tabIndex={-1}>
        Offering details
      </ButtonStyled>
      <SignUpForUpdatesButtonStyled portfolio={portfolio} onSignUpClose={onSignUpClose} view="primary" />
    </CardButtonsContainer>
  )
}

const RealPortfolioCardButtons = ({
  onClickActions,
  portfolio,
}: Omit<CardButtonsProps<LiveDataOffering>, 'onSignUpClose'>) => {
  const user = useUser()
  const { investStatus, isLoading } = useInvestStatus(portfolio)
  const isFull = portfolio.status === PortfolioStatus.Full
  const isManager = portfolio.manager.userId === user?.id

  return (
    <CardButtonsContainer onClick={stopClickPropagation}>
      {isLoading ? (
        <LoaderIcon size={16} />
      ) : (
        <>
          <ButtonStyled view="secondary" wide {...onClickActions} tabIndex={-1}>
            Offering details
          </ButtonStyled>
          {!isFull && !isManager && (
            <InvestButtonStyled portfolio={portfolio} investStatus={investStatus} tabIndex={-1} />
          )}
        </>
      )}
    </CardButtonsContainer>
  )
}

export const CardButtonsContainer = styled(Row)`
  min-height: 40px;
  justify-content: center;
  gap: 16px;
  opacity: 0;
  transition: opacity 0.2s ease-out;
`

const buttonStyles = css`
  min-width: 96px;
`

const ButtonStyled = styled(Button)`
  ${buttonStyles};
`

const SignUpForUpdatesButtonStyled = styled(SignUpForUpdatesButton)`
  ${buttonStyles};
`

const InvestButtonStyled = styled(InvestButton)`
  ${buttonStyles};
`
